@font-face {
  font-family:'vixSubtitleFont';
  src:url('../fonts/tradegothicltstd-bd2.eot') format('embedded-opentype'),
  url('../fonts/tradegothicltstd-bd2.woff') format('woff'),
  url('../fonts/tradegothicltstd-bd2.ttf') format('truetype'),
  url('../fonts/tradegothicltstd-bd2.svg#vixSubtitleFont') format('svg');
}
@font-face {
  font-family: 'vixBodyFont';
  src: url('../fonts/tradegothicltstd.eot');
  src: url('../fonts/tradegothicltstd.eot') format('embedded-opentype'),
  url('../fonts/tradegothicltstd.woff') format('woff'),
  url('../fonts/tradegothicltstd.ttf') format('truetype'),
  url('../fonts/tradegothicltstd.svg#vixBodyFont') format('svg');
}

* {
  transition:all 1s ease;
}

html {
  width: 100%;
  height: 100%;
}

body {
  font-family:'vixBodyFont';
  background-color:#38343b;
  color:#555;
  width: 100%;
  height: 100%;
  min-height: 300px;
}

@media (max-width: 767px) and (max-height: 568px) {
  body {
    min-height: 568px;
  }
}

@media (max-width: 319px) {
  .mobile h1 {
    font-size: 10vw !important;
  }
}

#content {
  width: 100%;
  height: 100%;
  padding: 20px 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bg-wrap {
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

#bgimage {
  position:absolute;
  top:0;
  left:0;
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
}

h1 {
  font-family:Roboto,HelveticaNeue-Light,"Helvetica Neue Light","Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif;
  -webkit-font-smoothing:antialiased;
  font-weight:300;
  color:#fff;
  margin-top:-.3em;
  font-size:2.8em;
}

h2 {
  font-family: 'vixSubtitleFont';
  color:#eb6c24;
  -webkit-font-smoothing:antialiased;
  font-size:3em;
  margin-top:10px;
}

.mobile {
  line-height:.5em;
}

.mobile h1 {
  margin-top:1em;
  font-size:2.5em;
}

.logo {
  margin-bottom:.5em;
  width: 140px;
}

.highlight {
  color:#eb6c24;
  font-weight:700;
}

footer {
  margin-top:5%;
  margin-top:5vh;
  font-size:.9em;
  color:#efefef;
}

footer p {
  // font-family: Roboto,HelveticaNeue-Light,"Helvetica Neue Light","Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif;
  -webkit-font-smoothing:antialiased;
  border-left:2px solid #eb6c24;
  padding-left:1em;
  opacity:.4;
  transition:opacity .5s ease;
}

a[href^=tel] {
  color:#efefef;
  text-decoration:none;
}

footer:hover p {
  opacity:1;
}

.browsehappy {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 1.4em;
  background-color: #ddd;
  margin: 0 !important;
  padding: 10px 20px;
}

.vixletLogo {
  width: 200px;
}